@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-sans text-gray-800 bg-white;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

h1 {
  @apply font-bold text-5xl text-gray-900 leading-tight;
}

h2 {
  @apply font-semibold text-4xl text-gray-900 mt-8 leading-tight;
}

h1 + h2 {
  @apply mt-3;
}

h3 {
  @apply mt-6;
  @apply font-bold text-xl text-gray-800;
}

h2 + h3 {
  @apply mt-2;
}

h4 {
  @apply mt-2;
  @apply font-semibold text-lg text-gray-800;
}

.decks {
  @apply w-full;
}

.magic-shadow {
  box-shadow: 0 1.9px 2.1px -6px rgba(0, 0, 0, 0.06),
    0 4.5px 4.1px -6px rgba(0, 0, 0, 0.041),
    0 8px 6.1px -6px rgba(0, 0, 0, 0.043),
    0 13.3px 8.1px -6px rgba(0, 0, 0, 0.047),
    0 22px 10.7px -6px rgba(0, 0, 0, 0.05),
    0 38.4px 15.5px -6px rgba(0, 0, 0, 0.052),
    0 83px 46px -6px rgba(0, 0, 0, 0.047),
    4px 5px 90px -10px rgba(182, 190, 197, 0);
}

/**
.deck-item {
  .thumbnail {
    transition: width 80ms ease-out;
  }
  &:hover .thumbnail {
    width: 150px;
  }
}*/

ul.checkmarks {
  li {
    @apply leading-normal mb-4;
  }
  li::before {
    content: "✓";
    color: #e7168b;
    font-weight: 700;
    padding-right: 10px;
  }
}

@media (min-width: 1024px) {
  .decks {
    flex: 0 1 33%;
  }
}

a {
  @apply font-medium;
  transition: color 200ms ease-in-out;
  color: #2674f6;
  &:hover {
    @apply text-blue-800;
  }
}

.dark-mode a {
  @apply text-gray-500;
  &:hover {
    @apply text-white;
  }
}

.fix-overflow {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.animate-scroll {
  transform-origin: top left;
  animation-name: animateScroll;

  animation-duration: 20s;

  //animation-timing-function: cubic-bezier(0.9, 0.06, 0.32, 0.77);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  //  animation-direction: alternate;
}

@media (min-width: 1024px) {
  .animate-scroll {
    animation-name: animateScroll;
  }
}

.shadow-inner-sm {
  box-shadow: inset -7px 0px 4px 0 rgba(0, 0, 0, 0.08);
}

.shadow-inner-lg {
  box-shadow: inset -7px 0px 4px 0 rgba(0, 0, 0, 0.08);
}

.shadow-right-xl {
  box-shadow: 10px 0px 20px 20px rgba(14, 7, 39, 0.15);
}
.gradient-cut {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.back-blur {
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.2);
}

.bg-blur {
  background: linear-gradient(
    -8deg,
    #8f9bb0 0%,
    #c1b7c8 30%,
    #dfd7d5 70%,
    #e8e7e8 100%
  );
}

@keyframes animateScroll {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-826px);
  }
}

@keyframes animateScrollMobile {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-303px);
  }
}

@keyframes highlight {
  from {
    @apply text-gray-900;
  }

  7% {
    @apply text-highlight;
  }

  15% {
    @apply text-highlight;
  }

  33%,
  100% {
    @apply text-gray-900;
  }
}

.appear {
  animation-duration: 5s;
  animation-fill-mode: ease-in-out;
  animation-name: highlight;
  animation-iteration-count: infinite;
  animation-direction: forwards;
  &.first {
    animation-delay: 1s;
  }
  &.second {
    animation-delay: 2s;
  }
  &.third {
    animation-delay: 3s;
  }
}

.bg-transition {
  transition: background-color ease-out 200ms;
}

.btn {
  @apply bg-blue-100 rounded px-4 py-2;
}

pre {
  @apply bg-gray-100 mb-4 p-3 rounded;
}

code {
  @apply bg-gray-100 text-gray-700 text-sm rounded;
  padding: 0.2rem 0.4rem;
}

ul ul,
ol ul {
  list-style-type: circle;
}
.content {
  @apply text-gray-700;
  @apply leading-relaxed;
}
